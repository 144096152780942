@font-face {
    font-family: 'atmospheric';
    src: url(./assets/fonts/aAtmospheric.ttf) format("truetype")
}

@font-face {
    font-family: 'nulshock';
    src: url(./assets/fonts/nulshock.otf) format("truetype")
}

@font-face {
    font-family: 'spyagency';
    src: url(./assets/fonts/spyagency.ttf) format("truetype")
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto.ttf) format("truetype")
}

@font-face {
    font-family: 'Spacemono';
    src: url(./assets/fonts/Spacemono.ttf) format("truetype")
}
